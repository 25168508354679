import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import "../../css/custom-css/custom-products-services.css"

import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/products/banner-produk.png"
import Seo from "../../components/seo"

const SarsCovRealTime = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit" />

      <Header productsPage />

      <BannerHeader
        background={bg}
        title="Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="">
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/indigen-real-time">
                        INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/diago-t790m">
                        DIAGO T790M Mutation Detection Kit
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <StaticImage src="../../images/products/elvapcrNew.png" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div class="row m-b30">
                      <div class="col-lg-12">
                        <div class="dlab-tabs  product-description tabs-site-button">
                          <h4 className="dlab-title mt-2">
                            Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                          </h4>
                          <Tabs>
                            <TabList className="nav nav-tabs ">
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Description</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Test Principle</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Intended Use</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Additional Information</Link>
                              </Tab>
                            </TabList>

                            <TabPanel class="tab-pane">
                              <p className="text-justify">
                                SARS-CoV-2 (Covid-19) is classified in the
                                coronaviridae family which first appeared in
                                early 2019 in Wuhan, China. This virus has
                                genetic material in the form of RNA and has a
                                high rate of spread through the air. In
                                Indonesia, this case was first discovered on
                                March 2, 2020 with the peak of the first wave at
                                the end of January reaching 12,000 cases and
                                followed by the peak of the second wave in July
                                2021 reaching 44,000 cases. To assist the
                                government in accelerating testing and tracing,
                                PT KalGen DNA produced SARS-CoV-2 Real-time
                                RT-PCR Kit and marketed by PT Enseval Medika
                                Prima (EMP), under the name ELVA SARS-CoV-2
                                Real-time RT-PCR Kit.
                              </p>
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                The Elva Diagnostic SARS-CoV-2 Real-time RT-PCR
                                Kit uses TaqMan-based real-time PCR technique to
                                conduct in vitro reverse transcription of
                                SARS-CoV-2 RNA, DNA amplification and
                                fluorescence detection. The kit targets specific
                                genomic regions of SARS-CoV-2: nucleocapsid (N)
                                gene and ORF1ab. The TaqMan probes for the two
                                amplicons are labeled with FAM and ROX
                                fluorescent dyes respectively to generate
                                target-specific signal. The kit includes an RNA
                                internal control (IC) containing the
                                bacteriophage MS2 target sequences to monitor
                                the processes from nucleic acid extraction to
                                fluorescence detection. The IC probe is labeled
                                with VIC/HEX fluorescent dye to differentiate
                                its fluorescent signal from SARS-CoV-2 targets.
                              </p>
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                The Elva Diagnostic SARS-CoV-2 Real-time RT-PCR
                                Kit is a real-time RT-PCR in vitro diagnostic
                                test intended for the qualitative detection of
                                nucleic acid from the SARS-CoV-2 virus in human
                                oropharyngeal swab and nasopharyngeal swab
                                specimens collected from individuals suspected
                                of COVID-19 or asymptomatic carrier by their
                                healthcare provider. The Elva Diagnostic
                                SARS-CoV-2 Real-time RT-PCR Kit is intended for
                                use by qualified and trained clinical laboratory
                                personnel specifically instructed and trained in
                                the techniques of real-time PCR and in vitro
                                diagnostic procedures.
                              </p>
                              <div className="mt-3"></div>
                              <StaticImage src="../../images/products/infoelvapcr1.png" />
                            </TabPanel>

                            <TabPanel class="tab-pane">
                              <div className="mt-3"></div>
                              <StaticImage src="../../images/products/infoelvapcr2.png" />
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </div>
    </div>
  )
}

export default SarsCovRealTime
